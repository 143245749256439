import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Sidenav from '../../vendor/libs/sidenav'
import helpers from './helpers'
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

class LayoutSidenav extends Component {
  layoutSidenavClasses() {
    let bg = this.props.sidenavBg
    if (this.props.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '')
    }

    return `bg-${bg} ` + (
      this.props.orientation !== 'horizontal'
        ? 'layout-sidenav'
        : 'layout-sidenav-horizontal container-p-x flex-grow-0'
    )
  }

  toggleSidenav(e) {
    e.preventDefault();
    helpers.toggleCollapsed();
  }

  isMenuActive(url) {
    return this.props.location.pathname.indexOf(url) === 0
  }

  isMenuOpen(url) {
    return this.props.location.pathname.indexOf(url) === 0 && this.props.orientation !== 'horizontal'
  }

  render() {
    return (
      <Sidenav orientation={this.props.orientation} className={this.layoutSidenavClasses()}>

          <React.Fragment>
              <div className="app-brand demo" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <Link to="/" className="app-brand-text demo sidenav-text font-weight-normal ml-2">
                    <span className="app-brand-logo demo">
                        <img width="145px" src={helpers.getUser().managerLogo ? helpers.getUser().managerLogo : (helpers.getLogo())}/>
                    </span>
                      {!helpers.getUser().managerLogo && <span className="app-brand-logo demo">
                          <small style={{textTransform: 'uppercase', fontWeight: '600'}}>{helpers.getUser().company.length > 20 ? (helpers.getUser().company.substr(0, 20) + '...') : helpers.getUser().company}</small>
                      </span>}
                  </Link>
                  <a href="#toggle" className="layout-sidenav-toggle sidenav-link text-large ml-auto" onClick={this.toggleSidenav}>
                      <i className="ion ion-md-menu align-middle"></i>
                  </a>
              </div>
              <Sidenav.Divider className="mt-0" />
          </React.Fragment>

        {/* Inner */}
        <div className={`sidenav-inner ${this.props.orientation !== 'horizontal' ? 'py-1' : ''}`}>
            <Sidenav.RouterLink to="/dashboard" active={this.isMenuActive('/dashboard')} exact={true} icon="ion ion-ios-speedometer">{this.props.t("Dashboard")}</Sidenav.RouterLink>

            {(helpers.getUser().countryId == "DK1915" || helpers.getUser().countryId == "DK1226") && helpers.hasRole("ROLE_CUSTOMER_TOOL_USER") && <Sidenav.RouterLink to="/orders/create?checkDevice=1" active={this.isMenuActive('/orders/create')} exact={true} icon="fas fa-desktop">{this.props.t("Create repair")}</Sidenav.RouterLink>}
            {helpers.hasAddon(6) && !helpers.hasRole("ROLE_CUSTOMER_TOOL_USER") && <Sidenav.RouterLink to="/cms/customers" active={this.isMenuActive('/cms/customers')} exact={true} icon="fas fa-link">{this.props.t("Customers")}</Sidenav.RouterLink>}
            {!helpers.hasRole('ROLE_INSURANCE') && !(helpers.hasAddon(25) && helpers.hasRole("ROLE_CUSTOMER_TOOL_USER")) && <Sidenav.Menu icon="ion ion-md-checkbox" linkText={this.props.t("Repair")} active={this.isMenuActive('/orders')} open={this.isMenuOpen('/orders')}>
                <Sidenav.RouterLink active={this.isMenuActive('/orders/create')} to="/orders/create">{this.props.t("Create order")}</Sidenav.RouterLink>
                <Sidenav.RouterLink active={this.isMenuActive('/orders/active')} to="/orders/active">{this.props.t("Active orders")}</Sidenav.RouterLink>
                <Sidenav.RouterLink active={this.isMenuActive('/orders/completed')} to="/orders/completed">{this.props.t("Completed orders")}</Sidenav.RouterLink>
                {helpers.getUser().countryId == "DK73" && <Sidenav.RouterLink active={this.isMenuActive('/orders/purchaseOrders')} to="/orders/purchaseOrders">{this.props.t("Purchase orders")}</Sidenav.RouterLink>}
                <Sidenav.RouterLink active={this.isMenuActive('/orders/cases/all')} to="/orders/cases/all">{this.props.t("Repairs")}</Sidenav.RouterLink>
            </Sidenav.Menu>}
            {helpers.hasRole('ROLE_INSURANCE') && <Sidenav.Menu icon="ion ion-md-checkbox" linkText={this.props.t("Repairs")} active={this.isMenuActive('/orders')} open={this.isMenuOpen('/orders')}>
                <Sidenav.RouterLink to="/orders/cases/create" active={this.isMenuActive('/orders/cases/create')} exact={true} >{this.props.t("Create repair")}</Sidenav.RouterLink>
                <Sidenav.RouterLink to="/orders/cases/all" active={this.isMenuActive('/orders/cases/all')} exact={true} >{this.props.t("Show repairs")}</Sidenav.RouterLink>
            </Sidenav.Menu>}

            {helpers.hasAddon(78) &&
                <Sidenav.Menu icon={"fas fa-map-marker"} linkText="Drop-in" active={this.isMenuActive('/drop-in')} open={this.isMenuOpen('/drop-in')}>
                    <Sidenav.RouterLink active={this.isMenuActive('/drop-in/tools')} to="/drop-in/tools">{this.props.t("Tools")}</Sidenav.RouterLink>
                    <Sidenav.RouterLink active={this.isMenuActive('/drop-in/report')} to="/drop-in/report">{this.props.t("Report")}</Sidenav.RouterLink>
                </Sidenav.Menu>
            }

            {helpers.hasRole(['ROLE_CUSTOMER_SUPER_ADMIN_USER', 'ROLE_CUSTOMER_READ_ONLY']) &&
                <Sidenav.RouterLink to="/departments/all" active={this.isMenuActive('/departments')} exact={true} icon="fas fa-store">{this.props.t("Departments")}</Sidenav.RouterLink>
            }


            {helpers.hasRole(['ROLE_CUSTOMER_SUPER_ADMIN_USER', 'ROLE_CUSTOMER_DEPARTMENT_USER', 'ROLE_CUSTOMER_DEPARTMENT_SUPER_USER', 'ROLE_CUSTOMER_READ_ONLY']) && helpers.getUser().countryId != "DK73" &&
                <Sidenav.RouterLink to="/users/all" active={this.isMenuActive('/users')} exact={true} icon="fas fa-users">{this.props.t("Users")}</Sidenav.RouterLink>
            }
            {helpers.hasRole(['ROLE_CUSTOMER_SUPER_ADMIN_USER', 'ROLE_CUSTOMER_DEPARTMENT_USER', 'ROLE_CUSTOMER_READ_ONLY']) && helpers.getUser().countryId == "DK73" && <Sidenav.Menu icon="fas fa-users" linkText={this.props.t("Users")} active={this.isMenuActive('/users')} open={this.isMenuOpen('/users')}>
                <Sidenav.RouterLink to="/users/all" active={this.isMenuActive('/users/all')} exact={true} >{this.props.t("Active")} </Sidenav.RouterLink>
                <Sidenav.RouterLink to="/users/resigning/false" active={this.isMenuActive('/users/resigning/false')} exact={true} >{this.props.t("Resigning")}</Sidenav.RouterLink>
                <Sidenav.RouterLink to="/users/resigning/true" active={this.isMenuActive('/users/resigning/true')} exact={true} >{this.props.t("Resigned")} </Sidenav.RouterLink>
            </Sidenav.Menu>}


            {helpers.hasAddon(100) && helpers.hasRole(['ROLE_CUSTOMER_SUPER_ADMIN_USER', 'ROLE_CUSTOMER_DEPARTMENT_SUPER_USER', 'ROLE_CUSTOMER_DEPARTMENT_USER', 'ROLE_CUSTOMER_READ_ONLY']) && <Sidenav.Menu icon="fas fa-cubes" linkText={this.props.t("Inventory")} active={this.isMenuActive('/inventory')} open={this.isMenuOpen('/inventory')}>
                <Sidenav.RouterLink active={this.isMenuActive('/inventory/devices')} to="/inventory/devices">{this.props.t("Devices")}</Sidenav.RouterLink>
                {helpers.hasAddon(10) && <Sidenav.RouterLink active={this.isMenuActive('/inventory/sim/cards')} to="/inventory/sim/cards">{this.props.t("Sim Cards")}</Sidenav.RouterLink>}
            </Sidenav.Menu>}

            {helpers.hasRole(['ROLE_CUSTOMER_SUPER_ADMIN_USER']) && helpers.hasAddon(24) && <Sidenav.Menu icon={"fas fa-store-alt"} linkText={this.props.t("Device as a Service")} active={this.isMenuActive('/device/shop')} open={this.isMenuOpen('/device/shop')}>
                <Sidenav.RouterLink active={this.isMenuActive('/device/shop/orders')} to="/device/shop/orders">{this.props.t("Orders")}</Sidenav.RouterLink>
                <Sidenav.RouterLink active={this.isMenuActive('/device/shop/return/option/all')} to="/device/shop/return/option/all">{this.props.t("Return options")}</Sidenav.RouterLink>
            </Sidenav.Menu>}

            {helpers.hasRole(['ROLE_CUSTOMER_SUPER_ADMIN_USER', 'ROLE_CUSTOMER_READ_ONLY']) && helpers.hasAddon(25) && <Sidenav.Menu icon={"fas fa-store-alt"} linkText={this.props.t("Device as a Service")} active={this.isMenuActive('/device/shop')} open={this.isMenuOpen('/device/shop')}>
                {helpers.hasRole('ROLE_CUSTOMER_SUPER_ADMIN_USER') && <Sidenav.RouterLink active={this.isMenuActive('/device/shop/assortment')} to="/device/shop/assortment/all">{this.props.t("Assortments")}</Sidenav.RouterLink>}
                {helpers.hasRole('ROLE_CUSTOMER_SUPER_ADMIN_USER') && <Sidenav.RouterLink active={this.isMenuActive('/device/shop/product')} to="/device/shop/product/all">{this.props.t("Products")}</Sidenav.RouterLink>}
                {helpers.hasRole('ROLE_CUSTOMER_SUPER_ADMIN_USER') && <Sidenav.RouterLink active={this.isMenuActive('/device/shop/accessory')} to="/device/shop/accessory/all">{this.props.t("Accessories")}</Sidenav.RouterLink>}
                {!helpers.hasAddon(44) && <Sidenav.RouterLink active={this.isMenuActive('/device/shop/agreement')} to="/device/shop/agreement/all">{this.props.t("Service agreements")}</Sidenav.RouterLink>}
                <Sidenav.RouterLink active={this.isMenuActive('/device/shop/orders')} to="/device/shop/orders">{this.props.t("Orders")}</Sidenav.RouterLink>
                <Sidenav.RouterLink active={this.isMenuActive('/device/shop/return/option/all')} to="/device/shop/return/option/all">{this.props.t("Return options")}</Sidenav.RouterLink>
            </Sidenav.Menu>}


            {(helpers.hasAddon(201) ||helpers.hasAddon(19)) && <Sidenav.Menu icon={"fas fa-shopping-cart"} linkText={this.props.t("Purchase")} active={this.isMenuActive('/webshop')} open={this.isMenuOpen('/webshop')}>
                <Sidenav.RouterLink active={this.isMenuActive('/webshop/carepacks')} to="/webshop/carepacks/create">{this.props.t("Carepacks")}</Sidenav.RouterLink>
                {helpers.hasAddon(19) && <Sidenav.RouterLink active={this.isMenuActive('/webshop/leasing')} to="/webshop/leasing">{this.props.t("Leasing")}</Sidenav.RouterLink>}
            </Sidenav.Menu>}

            {(helpers.hasRole('ROLE_INSURANCE') || helpers.hasAddon(14) || helpers.hasAddon(25) || helpers.hasAddon(78)) && <Sidenav.RouterLink to="/tools/all" active={this.isMenuActive('/tools/all')} exact={true} icon="fas fa-wrench">{this.props.t("Tools")}</Sidenav.RouterLink>}
            {helpers.hasRole('ROLE_INSURANCE') && <Sidenav.RouterLink to="/administration/informationFlow" active={this.isMenuActive('/administration/informationFlow')} exact={true} icon="fas fa-envelope">{this.props.t("Information flow")}</Sidenav.RouterLink>}
            {helpers.hasRole('ROLE_INSURANCE') && <Sidenav.RouterLink to="/reports/insurance" active={this.isMenuActive('/reports/insurance')} exact={true} icon="fas fa-signal">{this.props.t("Report")}</Sidenav.RouterLink>}
            {(!helpers.hasRole('ROLE_INSURANCE') && helpers.hasRole('ROLE_CUSTOMER_SUPER_ADMIN_USER')) && !helpers.hasRole('ROLE_HIDE_PRICES') && <Sidenav.RouterLink to="/reports/business" active={this.isMenuActive('/reports/business')} exact={true} icon="fas fa-signal">{this.props.t("Report")}</Sidenav.RouterLink>}
            {(helpers.hasAddon(71)) && !helpers.hasRole("ROLE_CUSTOMER_TOOL_USER") && <Sidenav.RouterLink to="/damage/prevention" active={this.isMenuActive('/damage/prevention')} exact={true} icon="fas fa-user-shield">{this.props.t("Damage prevention")}</Sidenav.RouterLink>}
            {(helpers.hasAddon(202)) && <Sidenav.RouterLink to="/reports/seedstock" active={this.isMenuActive('/reports/seedstock')} exact={true} icon="fas fa-exchange-alt">{this.props.t("Seedstock")}</Sidenav.RouterLink>}
            {(helpers.hasAddon(202)) && <Sidenav.RouterLink to="/reports/projectstock" active={this.isMenuActive('/reports/projectstock')} exact={true} icon="fas fa-exchange-alt">{this.props.t("B-Stock")}</Sidenav.RouterLink>}

            {!helpers.hasRole("ROLE_CUSTOMER_TOOL_USER") && <Sidenav.Menu icon="fas fa-info" linkText={this.props.t("Help & Info")} active={this.isMenuActive('/info')} open={this.isMenuOpen('/info')}>
                <Sidenav.RouterLink active={this.isMenuActive('/info/prices')} to="/info/prices">{this.props.t("Prices")}</Sidenav.RouterLink>
                <Sidenav.RouterLink active={this.isMenuActive('/info/agreements')} to="/info/agreements">{this.props.t("Agreements")}</Sidenav.RouterLink>
                <Sidenav.RouterLink active={this.isMenuActive('/info/files')} to="/info/files">{this.props.t("Files")}</Sidenav.RouterLink>
            </Sidenav.Menu>}


            {helpers.hasRole('ROLE_CUSTOMER_SUPER_ADMIN_USER') && !helpers.hasRole('ROLE_INSURANCE') &&
                <Sidenav.Menu icon="fas fa-cog" linkText={this.props.t("Administration")} active={this.isMenuActive('/administration')} open={this.isMenuOpen('/administration')}>
                    <Sidenav.RouterLink active={this.isMenuActive('/administration/budget')} to="/administration/budget">{this.props.t("Budget")}</Sidenav.RouterLink>
                    <Sidenav.RouterLink active={this.isMenuActive('/administration/settings')} to="/administration/settings">{this.props.t("Settings")}</Sidenav.RouterLink>
                    {helpers.hasRole(['ROLE_CUSTOMER_SUPER_ADMIN_USER']) && helpers.hasAddon(100) && <Sidenav.RouterLink active={this.isMenuActive('/inventory/administration/models')} to="/inventory/administration/models">{this.props.t("Models")}</Sidenav.RouterLink>}
                </Sidenav.Menu>
            }
            <Sidenav.RouterLink to="/contact" active={this.isMenuActive('/contact')} exact={true} icon="fas fa-envelope">{this.props.t("Contact")}</Sidenav.RouterLink>
        </div>

          {helpers.getUser().managerLogo && <React.Fragment>
              <div className="text-center mx-auto" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <Link to="/" className="app-brand-text demo sidenav-text font-weight-normal">
                      <span className="app-brand-logo demo">
                        <small as={"h6"} style={{ letterSpacing: '0.15em', fontWeight: '600' }} className={"text-white mt-4"}><strong>POWERED BY</strong></small>
                      </span>
                      <span className="app-brand-logo demo">
                        <img width="145px" src={helpers.getLogo()}/>
                    </span>
                  </Link>
              </div>
          </React.Fragment>}
      </Sidenav>

    )
  }
}

LayoutSidenav.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal'])
}

LayoutSidenav.defaultProps = {
  orientation: 'vertical'
}

export default connect(store => ({
    sidenavBg: store.theme.sidenavBg
}))(withTranslation()(LayoutSidenav));
